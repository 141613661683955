<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Colaborador"
                : "Cadastrar Colaborador"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Nome Colaborador"
                  v-model="formColab.nome"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="CPF"
                  v-model="formColab.cpf"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="RG"
                  v-model="formColab.rg"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <BaseDatePicker
                  label="Data Nasc."
                  v-model="formColab.data_nascimento"
                  :rules="[rules.required]"
                  @selectDate="formColab.data_nascimento = $event"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Email"
                  v-model="formColab.email"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Chapa"
                  v-model="formColab.chapa"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-autocomplete
                  label="Ativo"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                  v-model="formColab.ativo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar' ? editarColaborador() : cadastrarColaborador()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import serviceColab from "@/services/colaborador";
import sistema from "@/services/http/sistemaService";
export default {
  name: "ModalCadastroColaboradores",

  mixins: [rules],

  components: {
    BaseDatePicker: () => import("@/components/shared/BaseDatePicker")
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    labelBtn: {
      type: String
    },

    colaborador: {
      type: Object
    }
  },

  data() {
    return {
      formColab: {
        id_colaborador: "",
        nome: "",
        cpf: "",
        rg: "",
        chapa: "",
        ativo: "",
        data_nascimento: "",
        email: ""
      }
    };
  },

  watch: {
    dialog(value) {
      return value;
    },

    labelBtn(value) {
      return value;
    },

    colaborador(value) {
      this.formColab = value;
      this.formColab.data_nascimento = this.formardate(
        this.formColab.data_nascimento
      );
    }
  },

  methods: {
    formardate(date) {
      return date.split(" ")[0];
    },

    async cadastrarColaborador() {
      this.formColab.nome = this.formColab.nome.toUpperCase();
      this.formColab.id_colaborador = parseInt(this.formColab.cpf, 10);

      await sistema()
        .colaborador()
        .store(this.formColab, { notification: true });
      this.close();
    },

    async editarColaborador() {
      try {
        await serviceColab.editColaborador(
          this.formColab.id_colaborador,
          this.formColab
        );
        this.$notify({
          type: "success",
          text: "Colaborador editado com sucesso"
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao editar colaborador"
        });
      }
      this.close();
    },

    close() {
      this.$emit("close");
      this.$nextTick(() => {
        this.formColab = Object.assign({}, {});
        this.$refs.form.reset();
      });
    }
  }
};
</script>

<style></style>
