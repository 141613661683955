<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card>
      <v-container>
        <v-switch
          v-model="filters.ativo"
          true-value="S"
          false-value="N"
          :label="`${filters.ativo == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        ></v-switch>
        <BaseFilter
          label="Colaborador"
          v-model="filters.id_colaborador"
          service="sistemaService.colaborador"
          prepend-inner-icon="mdi-account"
          item-text="nome"
          item-value="id_colaborador"
          clearable
          single-line
          :filters="{
            ativo: filters.ativo,
            id_colaborador: filters.id_colaborador
          }"
          :hasCode="true"
          @change="handleFilter()"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>
<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseFilter from "@/components/shared/BaseSelectService";
export default {
  name: "FilterComissao",

  components: {
    FilterBottom,
    BaseFilter
  },

  data() {
    return {
      filters: {
        ativo: "S"
      }
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
