<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      sort-by="nome"
      @pageChange="fetchColaborador(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Pessoa</v-toolbar-title>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="99"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FilterColaborador
            @selectedFilters="fetchColaborador((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.nome`]="{ item }">
        {{ item.nome | TitleCase }}
      </template>
      <template v-slot:[`item.data_nascimento`]="{ item }">
        {{ item.data_nascimento | BrazilianStandardDate }}
      </template>
      <template v-slot:[`item.data_cadastro`]="{ item }">
        {{ item.data_cadastro | BrazilianStandardDate }}
      </template>
      <template v-slot:[`item.data_alteracao`]="{ item }">
        {{ item.data_alteracao | BrazilianStandardDate }}
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusAtivo[item.ativo].color"
          dark
          @click="statusItem(item)"
        >
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          v-can-access="100"
          icon
          @click="editItem(item)"
          color="orange"
          dark
          small
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="422"
          icon
          @click="deleteItem(item)"
          class="ml-2 white--text"
          color="red"
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom :name="'edit'" v-can-access="100" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="422"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadastroColaboradores
      :dialog="dialog"
      :labelBtn="labelBtn"
      :colaborador="colaborador"
      @close="refresh()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import IconBottom from "@/components/shared/bottons/IconBottom";
import sistema from "@/services/http/sistemaService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FilterColaborador from "./FilterColaborador";
import ModalCadastroColaboradores from "@/components/colaborador/ModalCadastroColaboradores";
import statusAtivo from "@/mixins/statusAtivo";
import dateMixin from "@/mixins/dateMixin";

export default {
  name: "TabelaColaboradores",

  mixins: [dateMixin, statusAtivo],

  components: {
    BaseTable,
    RegisterBottom,
    FilterColaborador,
    ModalCadastroColaboradores,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      labelBtn: "Salvar",
      filters: {},
      nLastPage: 0,
      headers: [
        { text: "Nome", value: "nome" },
        { text: "CPF", value: "cpf" },
        { text: "RG", value: "rg" },
        { text: "Data Nasc", value: "data_nascimento" },
        { text: "Chapa", value: "chapa" },
        { text: "Data Cad", value: "data_cadastro" },
        { text: "Data Alt", value: "data_alteracao" },
        { text: "Status", value: "ativo", align: "center" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      info: [],
      colaborador: {}
    };
  },

  methods: {
    async fetchColaborador(filter, nPage = 1) {
      this.loading = true;
      const { data } = await sistema()
        .colaborador()
        .show({
          page: nPage,
          ativo: "S",
          ...filter
        });
      this.nLastPage = data.last_page;

      this.info = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.colaborador = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialog = false;
      this.fetchColaborador(this.filters);
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          `Deseja deletar o colaborador ${item.nome}?`,
          "Essa ação não pode ser desfeita"
        );
        sistema()
          .colaborador(item.id_colaborador)
          .delete(
            {},
            { notification: true, message: "Colaborador deletado com sucesso!" }
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchColaborador(this.filters);
      }
    },

    statusEmpresa(item) {
      if (item == "S") item = "Ativo";
      if (item == "N") item = "Inativo";

      return item;
    }
  },

  mounted() {
    this.fetchColaborador();
  }
};
</script>

<style scoped>
.filters {
  align-items: center;
}
</style>
